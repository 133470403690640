// sanityClient.js
import { createClient } from '@sanity/client';
import imageUrlBuilder from '@sanity/image-url';

const client = createClient({
  projectId: process.env.REACT_APP_SANITY_STUDIO_PROJECT_ID,
  dataset: process.env.REACT_APP_SANITY_STUDIO_DATA_SET,
  apiVersion: '2023-07-16',
  useCdn: process.env.REACT_APP_SANITY_STUDIO_CDN_SEL,
});

const builder = imageUrlBuilder(client);

export function urlFor(source) {
  return builder.image(source);
}

export default client;