import React from 'react';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa'; // Using react-icons for arrow icons
import './carouselarrow.css';

const PrevButton = ({ onClick }) => (
    <button className="carousel-control-prev" onClick={onClick} aria-label="Previous">
        <FaAngleLeft/>
    </button>
);

const NextButton = ({ onClick }) => (
    <button className="carousel-control-next" onClick={onClick} aria-label="Next">
        <FaAngleRight  />
    </button>
);

export { PrevButton, NextButton };