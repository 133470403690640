import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import { Outlet, Link } from "react-router-dom";
//import Button from "react-bootstrap/Button";
//import Modal from "react-bootstrap/Modal";
import client, { urlFor } from "../sanityClient.js";
import { NavBar } from "../navBar.jsx";
import { Footer } from "../footer.jsx";
import "../assets/css/services.css"; // Import your CSS file for styling

/*function PartnershipModal(props) {
  return (
    <Modal {...props} size="lg" aria-labelledby="partnership-modal" centered>
      <Modal.Header closeButton>
        <Modal.Title id="partnership-modal" className="modal-headers">
          <h5>Partnership with Federal Agency</h5>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <b>
          Federal Contractors are encouraged to partner with VCSG to fill their
          cybersecurity workforce requirements. Veterans Cybersecurity Group was
          founded with the goal of addressing this Cybersecurity Workforce
          crisis by training, mentoring, and placing Active-Duty US Service
          Members as they transition into the civilian workforce. Our Federal
          Cybersecurity Professional Program has a team of recruiters that go
          out to US Military Bases presenting the program through the military
          Transition Assistance Program (TAP).
        </b>
      </Modal.Body>
      <Modal.Footer className="footer-buttons-layout">
        <Button className="btn-dark" onClick={props.onHide}>
          Close
        </Button>
        <a href="/calendly">
          <Button className="btn-dark">Book an Appointment</Button>
        </a>
      </Modal.Footer>
    </Modal>
  );
}

function CMMCModal(props) {
  return (
    <Modal {...props} size="lg" aria-labelledby="CMMC-modal" centered>
      <Modal.Header closeButton>
        <Modal.Title id="CMMC-modal" className="modal-headers">
          <h5>CMMC Certification</h5>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="container">
          <div className="center"></div>
          <div>
            <b>
              {" "}
              Veterans Cybersecurity Group (VCSG) is currently a 
              C3PAO Candidate. Once VCSG completes its pending DIBCAC 
              assessment, it will transition to an Authorized C3PAO, 
              certified to conduct DoD-mandated CMMC Level 2 assessments. 
              Alternatively, we also perform CMMC audit
              preparedness engagements utilizing our CMMC Registered
              Professionals..
            </b>
          </div>
          <br />
        </div>
      </Modal.Body>
      <Modal.Footer className="footer-buttons-layout">
        <Button className="btn-dark" onClick={props.onHide}>
          Close
        </Button>
        <a href="/calendly">
          <Button className="btn-dark">Book an Appointment</Button>
        </a>
      </Modal.Footer>
    </Modal>
  );
}

function FederalAgencyModal(props) {
  return (
    <Modal {...props} size="lg" aria-labelledby="agency-modal" centered>
      <Modal.Header closeButton>
        <Modal.Title id="agency-modal" className="modal-title">
          <h5>Federal Contracting Partners </h5>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="container">
          <div>
            <b>
              {" "}
              VCSG cybersecurity services are centered on, and follow guidance
              from, the National Institute of Standards and Technology (NIST)
              Risk Management Framework (RMF), the national standard mandated to
              federal agencies by FISMA to support cybersecurity risk
              management. These services include:
            </b>
          </div>
          <br />
          <div className="row">
            <ul>
              <li>
                Certification and Accreditation Process for Federal Information
                Systems
              </li>
              <li>High Value Asset (HVA) Protection</li>
              <li>Cybersecurity Assessments</li>
              <li>Cyber Supply Chain Risk Management (C-SCRM)</li>
              <li>Cyber Forensics</li>
              <li>Cybersecurity Planning</li>
              <li>Cybersecurity Training</li>
              <li>
                Federal Information Security Management Act (FISMA) Reporting
              </li>
              <li>Continuous Diagnostics and Mitigation</li>
            </ul>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="footer-buttons-layout">
        <Button className="btn-dark" onClick={props.onHide}>
          Close
        </Button>
        <a href="/calendly">
          <Button className="btn-dark">Book an Appointment</Button>
        </a>
      </Modal.Footer>
    </Modal>
  );
}*/

export default function Service2() {
  const [services, setServices] = useState([]);
  const [CMMCModalShow, setCMMCModalShow] = useState(false);
  const [partnershipModalShow, setPartnershipModalShow] = useState(false);
  const [federalAgenyModalShow, setFederalAgenyModalShow] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  // Function to check if the device screen is mobile
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 577); // Set a breakpoint for mobile devices
    };

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Initial check on mount
    handleResize();
    window.scrollTo(0, 0);
    // Clean up the event listener on unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    client
      .fetch(
        `*[_type == "service"]{
          _id,
          title,
          slug,
          image,
          description1,
          description2,
          link
        }`
      )
      .then((data) => setServices(data))
      .catch(console.error);
  }, []);

  return (
    <>
      <NavBar />
      <section className="heroVideoSection">
        <div className="bg-white-06">
          {/* Conditional rendering based on the device type */}
          {isMobile ? (
            <img
              className="stillHeroImage"
              src="/images/services_images/earth.png"
              alt="Hero Image"
            />
          ) : (
            <video className="heroVideo" loop autoPlay muted>
              <source
                src="https://www.erasolawsdevtest.com/assets/videos/globe.mp4"
                type="video/mp4"
              />
              Your browser does not support the video tag.
            </video>
          )}

          <div className="overlay"></div>

          <Container className="text-center">
            <Row>
              <Col className="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2 heroText">
                <h1 className="fw-bold m-0">
                  DISCOVER THE SERVICES VETERANS CYBERSECURITY GROUP HAS TO
                  OFFER!
                </h1>
              </Col>
            </Row>
          </Container>
        </div>
      </section>

      <br />
      <Container fluid>
        <Row className="g-4 g-lg-5 service-container">
          {services.map((service) => (
            <Col
              key={service._id}
              md={4}
              lg={4}
              className="center content-selection-service"
            >
              <Link to={service.link}>
                <div className="border p-4 content-container-service">
                  <Image
                    className="service-images"
                    src={urlFor(service.image).url()}
                    alt={service.title}
                  />
                  <h5 className="fw-medium mt-2">{service.title}</h5>
                  <br />
                  <p>{service.description1}</p>
                  {service.description2 && <p>{service.description2}</p>}
                </div>
              </Link>
            </Col>
          ))}
        </Row>
      </Container>
      <Outlet />
      <Footer />
    </>
  );
}