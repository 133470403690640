import React, { useEffect } from "react";
import "./homePage.css";
import "./responsiveHome.css";
import { Carousel } from "react-bootstrap";
import { useState } from "react";
import "../../assets/css/button.css";
import axios from "axios";
import { Link } from "react-router-dom";
import { Footer } from "../../../src/footer";
import { NavBar } from "../../navBar";
//Import the Sanity Client
import sanityClient from '../../sanityClient';
import {PrevButton, NextButton } from '../../components/navigation/carouselarrow';

export default function Home() {
    useEffect(() => {
        window.scrollTo(0, 0);
        console.log("Footer");
    }, []);
    return (
        <>
            <NavBar />
            <HeroSection />
            <CoreOfferings />
            <ServicesSection />
            <FederalContractorSection />
            <Blog />
            <Footer />
        </>
    );
}

// -- start Home --
function HeroSection() {
    return (
        <section className='hero-container section'>
            <div className='video-container'>
                <video
                    autoPlay
                    loop
                    muted
                    disabled
                    onClick={(e) => e.preventDefault()}
                >
                    <source
                        src='https://www.erasolawsdevtest.com/assets/images/home-images/hero-video.mp4'
                        type='video/mp4'
                    ></source>
                </video>
            </div>
            <div className='hero-content'>
                <div className='hero-content-items'>
                    <h1 id='hero-header'>Our Mission</h1>
                    <p id='hero-info'>
                    Veterans Cybersecurity Group's (VCSG) multifaceted mission includes providing cybersecurity services and training to Federal Agencies and contractors while supporting the transition of Active-Duty US Service Members into the civilian Cybersecurity Workforce.  
                    </p>
                    <a href='/calendly'>
                        <button className='btn-dark' id='hero-button'>
                            Learn more
                        </button>
                    </a>
                </div>
                <img
                    id='hero-logo'
                    src='/images/home-images/logo.png'
                    alt='hero-logo'
                ></img>
            </div>
        </section>
    );
}

// -- start Core Offerings --
function CoreOfferings() {
    return (
        <section className='core-container'>
            <header className='core-header'>
                <h1>Core Offerings</h1>
            </header>
            <div className='bg-div'>
                <img
                    className='core-bg-img bg-img'
                    src='/images/home-images/bg.jpg'
                    alt='core-bg-img'
                />
            </div>
            <div className='core-content'>
                <div className='core-left'>
                    <a href='/government'>
                        <img
                            src='/images/home-images/core-services.jpg'
                            alt='core-services-img'
                        ></img>
                    </a>
                </div>
                <div className='core-right'>
                    <div className='core-right-content'>
                        <a href='/government'>
                            <ul>
                                <li className='list-item'>
                                    Cybersecurity Services to Federal Agency’s
                                </li>
                                <li className='list-item'>
                                    Federal Cybersecurity Program Development
                                </li>
                                <li className='list-item'>
                                    Zero Trust Modernization & Cloud Migration
                                </li>
                                <li className='list-item'>
                                    Cybersecurity Training
                                </li>
                                <li className='list-item'>
                                    Partnership with Federal Contractors
                                </li>
                                <li>
                                    <button className='btn-dark'>
                                        Learn more
                                    </button>
                                </li>
                            </ul>
                        </a>
                    </div>
                </div>
            </div>
        </section>
    );
}

{/*// -- start Services Old --
function ServicesSection() {
    return (
        <section className='services-container'>
            <div className='services-bg-div bg-div'>
                <img
                    className='services-bg-img bg-img'
                    alt='services-bg'
                    src='/images/home-images/services-bg.png'
                ></img>
            </div>
            <header className='services-header'>
                <h1>Services</h1>
            </header>
            <div className='services-cards'>
                {servicesObj.map((props) => (
                    <ItemCard
                        src={props.src}
                        alt={props.alt}
                        cardHeader={props.cardHeader}
                        href={props.href}
                        buttonContent={props.buttonContent}
                        cardClassName='services-card'
                        key={props.id}
                    />
                ))}
            </div>
        </section>
    );
}*/}

//Start Services New
function ServicesSection() {
    const [services, setServices] = useState([]);
    const [startIndex, setStartIndex] = useState(0);
    const [autoScroll, setAutoScroll] = useState(true);
    const [itemsPerSlide, setItemsPerSlide] = useState(4);
    const chunkSize = itemsPerSlide;

    useEffect(() => {
        sanityClient
            .fetch(
                `*[_type == "service"]{
                    _id,
                    title,
                    image{
                        asset->{
                            _id,
                            url
                        },
                        alt
                    },
                    link,
                    buttonContent
                }`
            )
            .then((data) => setServices(data))
            .catch((error) => console.error(error));
    }, []);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 769) {
                setItemsPerSlide(1);
            } else {
                setItemsPerSlide(4);
            }
        };

        window.addEventListener('resize', handleResize);
        handleResize(); // Call handler right away so state gets updated with initial window size

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        let interval;
        if (autoScroll) {
            interval = setInterval(() => {
                setStartIndex((prevIndex) => (prevIndex + 1) % services.length);
            }, 3000); // Adjust interval as needed
        }
        return () => clearInterval(interval);
    }, [autoScroll, services.length]);

    const nextChunk = () => {
        setStartIndex((prevIndex) => (prevIndex + 1) % services.length);
        setAutoScroll(false);
    }

    const prevChunk = () => {
        setStartIndex((prevIndex) => (prevIndex - 1 + services.length) % services.length);
        setAutoScroll(false);
    }

    const getChunk = (startIdx) => {
        const endIndex = (startIdx+chunkSize) % services.length;
        if(endIndex > startIdx)
        {
            return services.slice(startIdx, endIndex);
        }
        else
        {
            return [...services.slice(startIdx, services.length), ...services.slice(0, endIndex)];
        }
    }


    return (
        <section className='services-container'>
            <div className='services-bg-div bg-div'>
                <img
                    className='services-bg-img bg-img'
                    alt='services-bg'
                    src='/images/home-images/services-bg.png'
                ></img>
            </div>
            <header className='services-header'>
                <h1>Services</h1>
            </header>
            <div className='carousel-container'>
                <PrevButton onClick={prevChunk} />
                <Carousel controls={false} indicators={false} interval={null}>
                    {Array(Math.ceil(services.length / chunkSize)).fill().map((_, idx) => (
                            <Carousel.Item key={idx}>
                                <div className='services-cards'>
                                    {getChunk((startIndex + idx)%services.length).map((service) => (
                                        <ItemCard
                                            src={service.image.asset.url}
                                            alt={service.image.alt}
                                            cardHeader={service.title}
                                            href={'/services'}
                                            buttonContent={service.buttonContent}
                                            cardClassName='services-card'
                                            key={service._id}
                                        />
                                    ))}
                                </div>
                            </Carousel.Item>
                    ))}
                </Carousel>
                <NextButton onClick={nextChunk} />
            </div>
        </section>
    );
}

const servicesObj = [
    {
        src: "/images/home-images/services-1.jpg",
        alt: "image.png",
        cardHeader: "Government Program Developments",
        href: "/government",
        id: 0,
    },
    {
        src: "/images/home-images/services-2.jpg",
        alt: "image.png",
        cardHeader:
            "DoD Skillbridge Mentoring Program",
        href: "/skillbridge",
        id: 1,
    },
    {
        src: "/images/home-images/services-3.jpg",
        alt: "image.png",
        cardHeader:
            "Federal Cybersecurity Governance, Compliance, Risk Management ",
        href: "/women",
        id: 2,
    },
];

function ItemCard(props) {
    return (
        <div className='services-card'>
            <a
                href={props.href}
                className={`services-card-content ${props.cardClassName}`}
            >
                <img src={props.src} alt={props.alt}></img>
                <div className='services-card-text-content'>
                    <h5>{props.cardHeader}</h5>
                    <div>
                        <button className='btn-dark'>Learn more</button>
                    </div>
                </div>
            </a>
        </div>
    );
}

function FederalContractorSection() {
    return (
        <section className='fc-container '>
            <div className='bg-div'>
                <img
                    className='bg-img'
                    src='/images/home-images/fc-bg.jpg'
                    alt='fc-bg-img'
                />
            </div>
            <header className='fc-header'>
                <h1>Federal Contractors</h1>
            </header>
            <div className='cards-two-container'>
                {fcObj.map((props) => (
                    <ItemCardTwo
                        src={props.src}
                        alt={props.alt}
                        cardHeader={props.cardHeader}
                        info={props.info}
                        flip={props.flip}
                        key={props.id}
                    />
                ))}
            </div>
        </section>
    );
}

const fcObj = [
    {
        src: "/images/home-images/federal-1.jpg",
        alt: "image.png",
        cardHeader: "CMMC Consulting and Certification",
        info: "The Cybersecurity Maturity Model Certification (CMMC) serves as a comprehensive cybersecurity standard for upcoming Department of Defense (DoD) acquisitions. Its purpose is to ensure heightened confidence that contractors within the Defense Industrial Base (DIB) possess the capability to safeguard Controlled Unclassified Information (CUI) at a level corresponding to the associated risk.",
        id: 0,
    },
    {
        src: "/images/home-images/federal-2.jpg",
        alt: "image.png",
        cardHeader: "Contractor Partnering & Joint Ventures",
        info: "Contractor partnering and joint ventures in cybersecurity, especially involving veterans, establish a dynamic and strategic approach to addressing the evolving challenges in the defense and cybersecurity sectors. This collaborative effort aims to leverage the unique skills and experiences of military professionals, fostering a symbiotic relationship that significantly contributes to the success of cybersecurity initiatives.",
        flip: true,
        id: 1,
    },
    {
        src: "/images/home-images/federal-3.jpg",
        alt: "image.png",
        cardHeader: "Security Clearance Staff Augmentation",
        info: "TS/SCI staff augmentation is a strategic approach that addresses the specific requirements of projects involving classified information. By leveraging personnel with the necessary security clearances, this model ensures compliance, enhances project flexibility, and contributes specialized expertise to mission-critical objectives within secure environments.",
        id: 2,
    },
];

function ItemCardTwo(props) {
    return (
        <>
            {/* flipped */}
            {props.flip ? (
                <a
                    href='/federalContract'
                    className={`fc-card flip`}
                    key={props.id}
                >
                    <div className='fc-card-img-div'>
                        <img
                            className='fc-card-img'
                            src={props.src}
                            alt={props.alt}
                        ></img>
                    </div>
                    <div className='fc-card-content'>
                        <h3>{props.cardHeader}</h3>
                        <p>{props.info}</p>
                    </div>
                </a>
            ) : (
                // regular
                <a href='/federalContract' className={`fc-card`} key={props.id}>
                    <div className='fc-card-content'>
                        <h3>{props.cardHeader}</h3>
                        <p>{props.info}</p>
                    </div>
                    <div className='fc-card-img-div'>
                        <img
                            className='fc-card-img'
                            src={props.src}
                            alt={props.alt}
                        ></img>
                    </div>
                </a>
            )}
        </>
    );
}

// -- start blog --
function Blog() {
    const [index, setIndex] = useState(0);
    const [blogObj, setBlogObj] = useState();
    const [canDisplay, setCanDisplay] = useState(false);

    useEffect(() => {
        axios
            .get(
                'https://z2v7up70.api.sanity.io/v1/data/query/production?query=*[_type == "blog"][0...10] | order(datePublished desc){title, "imageUrl": image.asset->url, categories[]->{"categoryName": categoryName, "visible": visible}, datePublished, description, slug, content}'
            )
            .then((res) => {
                setBlogObj(res.data.result);
                setCanDisplay(true);
            })
            .catch((err) => console.log(err));
    }, []);

    const handleIndex = (selectedIndex) => {
        setIndex(selectedIndex);
    };
    return (
        <div className='blog-container'>
            <header className='blog-header'>
                <h1>Blog</h1>
            </header>
            <Carousel
                activeIndex={index}
                onSelect={handleIndex}
                className='blog-section'
            >
                {canDisplay ? (
                    blogObj.map((post) => {
                        return (
                            <Carousel.Item key={post.title}>
                                <Link
                                    to={`/blog/${post.slug.current}`}
                                    state={{ post: post }}
                                >
                                    <img
                                        className='carousel-img'
                                        src={post.imageUrl}
                                        alt={`${post.title} featured image`}
                                    ></img>
                                    <Carousel.Caption>
                                        <h3>{post.title}</h3>
                                        <p>{post.description}</p>
                                    </Carousel.Caption>
                                </Link>
                            </Carousel.Item>
                        );
                    })
                ) : (
                    <p>Loading</p>
                )}
            </Carousel>
        </div>
    );
}
