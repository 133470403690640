import Container from "react-bootstrap/Container";
import React from "react";
import "./cmmc.css";
import Hero from "../../components/hero";
import cmmcImage from "../../assets/cmmcimages/cmmchero.jpg";
import { Footer } from "../../../src/footer";
import {NavBar} from "../../navBar.jsx";
import cmmcImage2 from "../../assets/cmmcimages/cmmcheroclnew.png";
import cmmcLevels from "../../assets/cmmcimages/cmmclevels3.png";
import cmmcHelp from "../../assets/cmmcimages/cmmchelp.png";
import CMMCHelpComponent from "../../components/cmmc/CmmcHelp.jsx";

export default function CMMC() {
  return (
    //Code goes in here.
    <>
      <NavBar />
      <Hero heroImage={cmmcImage} header1 = "VCSG covers all your CMMC needs: consulting, assessments, publishing, and training."/>
      <hr />
      <br />
      <section className="mainContainer">
        <Container>
          <div className='container1'>
            <div className='textContent'>
                <h2>CMMC Overview</h2>
                <p className="aboutFont">
                The Cybersecurity Maturity Model Certification (CMMC), developed 
                by the Department of Defense (DoD), aims to enhance and secure the 
                cybersecurity of contractors in the Defense Industrial Base (DIB). 
                This framework represents a significant shift in cybersecurity 
                regulations, emphasizing rigorous cyber hygiene through defined 
                processes, practice requirements, and audits by CMMC Third-Party 
                Assessment Organizations (C3PAOs).
                </p>
                <p className="aboutFont">
                The main objective of the CMMC is to protect sensitive information, 
                such as Federal Contract Information (FCI) and Controlled Unclassified 
                Information (CUI), and control access to critical data. This approach 
                reduces cyber threats and secures the Defense Supply Chain (DSC).
                </p>
            </div>
            <div className='imageContent'>
                <img
                    src={cmmcImage2}
                    alt="CMMC Overview"
                />
            </div>
          </div>
          <hr />
          <br />
          <div className="breakup">
            <div className='container1'>
              <div className='textContent'>
                <h2>CMMC Framework Levels</h2>
                <p className="aboutFont">
                  The Cybersecurity Maturity Model Certification (CMMC) has evolved from Model 1's five levels to Model 2's streamlined three-level structure. In Model 2, Level 1 (Foundational) focuses on basic safeguarding of Federal Contract Information (FCI), similar to Model 1's first level. Level 2 (Advanced) consolidates the practices from Levels 2 and 3 of Model 1, requiring full NIST SP 800-171 implementation for Controlled Unclassified Information (CUI). Level 3 (Expert) combines the advanced measures of Model 1's Levels 4 and 5, defending against advanced persistent threats (APTs).
                </p>
              </div>
            </div>
            <div className='container1'>
              <div className='imageContent'>
                <img
                  src={cmmcLevels}
                  alt="CMMCLevels"
                />
              </div>
            </div>
          </div>
          <hr />
          <br />
          <Container>
            <div className='container1' style={{paddingTop: '60px'}}>
                <div className='imageContent'>
                    <img
                        src={cmmcHelp}
                        alt="cmmcHelp"
                    />
                </div>
            </div>
          </Container>
          <CMMCHelpComponent />
        </Container>
      </section>
      <Footer />
      </>
  );
}
