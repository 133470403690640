// CMMCHelpComponent.jsx
import React, { useEffect, useState } from 'react';
import sanityClient from '../../sanityClient';
import { Container } from 'react-bootstrap';
import '../../pages/cmmc/cmmc.css';

const CMMCHelpComponent = () => {
  const [cmmcHelpItems, setCmmcHelpItems] = useState([]);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "CMMCHelp"]{
          title,
          url,
          image{
            asset->{
              _id,
              url
            },
            alt
          },
          bodyParagraph
        }`
      )
      .then((data) => setCmmcHelpItems(data))
      .catch(console.error);
  }, []);

  return (
    <Container className="boxLife">
      {cmmcHelpItems.map((item, index) => (
        <a
          href={item.url} 
          target="_blank" 
          rel="noopener noreferrer" 
          key={index} 
          className={`tileParent1 ${index < 2 ? 'row1' : 'row2'}`}
          style={{ textDecoration: 'none', color: 'inherit', }}  
        >
          {/*<div className={`tileParent1 ${index < 2 ? 'row1' : 'row2'}`} key={index}>*/}
            <div className="tileTop1">
              <img className="tileTopIMGs" src={item.image.asset.url} alt={item.image.alt} />
              <h5>{item.title}</h5>
            </div>
            <p className="bodyParagraph1">{item.bodyParagraph}</p>
         {/*</div>*/}
        </a>
      ))}
    </Container>
  );
};

export default CMMCHelpComponent;