import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import React from "react";
import './hero.css';

export default function Hero ({heroImage,header1,header2, button}) {
    return (
        <div className="gradient-background">
            <section className="bg-white-06">
                <section 
                    className="heroImageSkillbridge d-flex align-items-center justify-content-center text-center" 
                    style={{backgroundImage:`linear-gradient(to top, white, rgba(255, 255, 255, 0.02)),url("${heroImage}")`, height: '60vh'}}
                    >
                
                    <Container className=" text-center">
                        <Row>
                            <Col className="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2 heroText">
                                <h1 className="fw-bold m-0 text-with-border" > {header1} </h1>
                                <h2 className="fw-bold m-0 text-with-border" > {header2}</h2>
                                <>{button}</>
                            </Col>
                        </Row>
                        {/*end row*/}
                    </Container>
                    {/*end container*/}
                </section>
            </section>
        </div>
    )
};