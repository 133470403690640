import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import React from "react";
import Home from "./pages/homePage/HomePage.jsx";
import Service from "./pages/Service.jsx";
import Contact from "./pages/contact/Contact.jsx";
import About from "./pages/About/About.jsx";
import Calendly from "./pages/calendly/Calendly.jsx";
import FederalContract from "./pages/federalContracts/FederalContract";
import SkillBridge from "./pages/SkillBridge.jsx";
import Women from "./pages/Women/Women.jsx";
import ZeroTrust from "./pages/zeroTrust/ZeroTrust.jsx";
import BlogPage from "./pages/blogPage/BlogPage.jsx";
import Government from "./pages/governmentPage/Government.jsx";
import Post from "./pages/post/Post.jsx";
import Form from "./pages/Women/WomenForm/WomenForm.jsx";
import Partnership from "./pages/Partnership.jsx";
import Service2 from "./pages/Service2.jsx";
import CMMC from "./pages/cmmc/cmmc.jsx"


function App() {
  return (
    <>

      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/services" element={<Service2 />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/about" element={<About />} />
        <Route path="/calendly" element={<Calendly />} />
        <Route path="/federalContract" element={<FederalContract />} />
        <Route path="/partnership" element={<Partnership />} />
        <Route path="/skillbridge" element={<SkillBridge />} />
        <Route path="/women" element={<Women />} />
        <Route path="/zeroTrust" element={<ZeroTrust />} />
        <Route path="/blog" element={<BlogPage />} />
        <Route path="/government" element={<Government />} />
        <Route path="/blog/:slug" element={<Post />} />
        <Route path="/womenform" element={<Form />} />
        <Route path="/oldservices" element={<Service />} />
        <Route path="/cmmc" element={<CMMC />} />
      </Routes>
      
    </>
  );
}

export default App;
